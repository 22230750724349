.services {
  text-align: center;
}

.services__firstRow{
  height: 100%;
  margin-bottom: 50px;
  display: flex;
  align-items: flex-start;
}

.services__secondRow{
  width: 101vw;
  position: absolute;
  left: 0;
}

.services__firstCol{
  height: 100%;
  margin-bottom: 40px;
}

.services__secondCol{
  width: 75%;
  padding-left: 5%;
  padding-right: 5%;
  text-align: justify;
  text-justify: inter-word;
  font-size: 16pt;
}

.services__infoRows{
  margin-bottom: 15px;
}

.services__navCotainer{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 400px;
  padding: 0
}

.services__nav{
  width: 100%;
}

