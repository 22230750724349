.nav-bar{
  height: 150px;
}

#spacer{
  width: 62%;
}

.navbar__image{
  width: 220px;
}