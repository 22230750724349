.footerPages{
  background-color: var(--primary-color);
}

.footer-link{
  color: white;
  text-decoration: none;
}

.footerPages__row-height{
  height: 100px;
}

.footerPages__row-info{
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.footerPages__image{
  width: 150px;
}

.footerPages__white{
  color: white;
}

.footerPages__col-main{
  display: flex;
  align-items: center;
  justify-content: center;
}
.footerPages__row-main{
  height: 100px;
}