.space-bar{
  width: 100vw;
  height: 30px;
  background-color: var(--primary-color);
}

#spacer{
  width: 62%;
}

.navbarPages__image{
  width: 150px;
}