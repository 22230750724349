.ligButton{
  width: 100%;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  font-size: 14pt;
}

.ligButton:hover{
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.btn.active{
  color: white;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.btn.disabled, .btn:disabled, fieldset:disabled .btn {
  color: white !important;
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  opacity: 0.5 !important;
}