.contact {
  text-align: center;
}

.contact__firstRow{
  height: 100%;
  margin-bottom: 50px;
  display: flex;
  align-items: flex-start;
}

.contact__secondRow{
  width: 101vw;
  position: absolute;
  left: 0;
}

.contact__firstCol{
  height: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.contact__secondCol{
  height: 100%;
  padding-left: 5%;
  padding-right: 5%;
  text-align: justify;
  text-justify: inter-word;
  font-size: 16pt;
}

.contact__infoRows{
  margin-bottom: 15px;
}

.contact__margin-top{
  margin-top: 60px;
}
