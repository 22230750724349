.propBar{
  width: 100%;
  color: grey;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  font-size: 12pt;
}

.propBar__container{
  padding-left: 2%;
  display: flex;
  justify-content: space-between;
  column-gap: 30px;
}

.propBar__container a{
  color: grey;
}