.about {
  text-align: center;
}

.space-bar{
  width: 100vw;
  height: 30px;
  background-color: var(--primary-color);
}

.about__firstRow{
  height: 100%;
  margin-bottom: 50px;
  display: flex;
  align-items: flex-start;
}

.about__secondRow{
  width: 101vw;
  position: absolute;
  left: 0;
}

.about__firstCol{
  height: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.about__secondCol{
  height: 100%;
  padding-left: 5%;
  padding-right: 5%;
  text-align: justify;
  text-justify: inter-word;
  font-size: 16pt;
}