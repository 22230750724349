@font-face {
  font-family: 'Arapey';
  src: url('https://light-hill-temp.s3.ap-southeast-2.amazonaws.com/website/arapey.ttf');
}

.App{
  font-family: 'Arapey';
}

.primaryBackground {
  background-color: gray
}

.flex{
  display: flex;
}

.flex-sb{
  display: flex;
  justify-content: space-between;
}

.left-text{
  text-align: left;
}

.margin-bottom{
  margin-bottom: 20px;
}