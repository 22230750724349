.contactForm{
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10%;
}

.contactForm__input{
  border: 1px solid var(--primary-color);
}

.contactForm__label{
  font-weight: 600;
}

.contactForm__alignLeft{
  text-align: left;
}

.contactForm__width45{
  width: 45%;
}

.contactForm__width100{
  width: 100%;
}

.contactForm__height180{
  height: 180px;
}

.contactForm__height140{
  height: 140px;
}