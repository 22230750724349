.home {
  text-align: center;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.bg-text{
  color: white;
  font-size: 35px;
  height: 100px;
  padding-right: 30px;
  padding-top: -30px;
  text-align: left;
  z-index: 2;
}

.mobile-cover{
  background: rgba(0,0,0,0.6);
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
}