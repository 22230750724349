.nav-text-page{
  color: var(--primary-color);
  font-size: 20px;
}

#basic-nav-dropdown-page{
  color: var(--primary-color);
  font-size: 20px;
}

#basic-nav-dropdown{
  color: white;
  font-size: 20px;
}

.nav-text{
  color: white;
  font-size: 20px;
}